












































































import Component from 'vue-class-component';
import Vue from 'vue';
import {Action, Getter} from 'vuex-class';
import {QuizActions} from '@/modules/quiz/store/actions';
import {QuizGetters} from '@/modules/quiz/store/getters';
import QuizModule from '@/modules/quiz';
import RsButton from '@/shared/components/buttons/button.vue';
import {UUID} from '@/shared/constants/UUID';
import {IApiTalentQuizQuestion} from '@/shared/models/IApiTalentQuizQuestion';
import RsQuizHeader from '@/modules/quiz/components/main/header.vue';
import RsRadio from '@/shared/components/fields/radio.vue';
import {required} from 'vuelidate/lib/validators';
import RsLoading from '@/shared/components/loading/loading.vue';
import {Watch} from 'vue-property-decorator';
import {IApiTalentQuizCategoryResult} from '@/shared/models/IApiTalentQuizCategoryResult';
import {QuizRouter} from '@/modules/quiz/router';
import {QUIZ_SERVICE} from '@/shared/services/quiz.service';
import {QUIZ_STATES} from '@/shared/constants/QUIZ_STATES';
import {AssesmentsRouter} from '@/modules/dashboard/modules/assesments/router';
import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import RsCountdown from '@/shared/components/countdown/countdown.vue';
import {DATE_SERVICE} from '@/shared/services/date.service';

interface QuestionForm {
  answer: number | '';
}

@Component({
  components: {RsCountdown, RsLoading, RsButton, RsRadio, RsQuizHeader},
  validations: {
    form: {
      answer: {
        required
      }
    }
  },
})
export default class QuizQuestion extends Vue {
  @Action(QuizActions.NEXT_QUESTION, {namespace: QuizModule.namespace})
  nextQuestion: (answer: number | undefined) => Promise<IApiTalentQuizQuestion>;
  @Action(QuizActions.CONTINUE_QUIZ, {namespace: QuizModule.namespace})
  continueQuiz: (id: UUID) => void;

  @Getter(QuizGetters.GET_QUIZZES, {namespace: QuizModule.namespace}) quizzes: IApiTalentQuiz[];
  @Getter(QuizGetters.GET_QUESTION, {namespace: QuizModule.namespace}) question: IApiTalentQuizQuestion;
  @Getter(QuizGetters.GET_RESULTS, {namespace: QuizModule.namespace}) results: IApiTalentQuizCategoryResult[];

  id: UUID | null = null;

  form: QuestionForm = {
    answer: ''
  }

  imageLoaded = false;

  sending = false;
  timeLeft = -1;

  quiz?: IApiTalentQuiz;

  availableUntil: string | null = null;

  userQuizId?: UUID;

  @Watch('question', {immediate: true, deep: true})
  onQuestionChange(question: IApiTalentQuizQuestion | null) {
    this.form.answer = '';
    this.imageLoaded = false;
    this.$v.$reset();

    if(question?.remainingSeconds){
      this.availableUntil = DATE_SERVICE.instance().add(question.remainingSeconds, 'seconds').toISOString();
    }
    if(question?.userQuizId){
      this.userQuizId = question?.userQuizId;
    }

    if(!question && this.results){
      this.$track.sendEvent('QuizResults', {
        quizId: this.quiz?.quizId,
        name: this.quiz?.name,
        results: this.results.map((r) => `${r.categoryName} - ${r.level}/${r.maxLevel}`)
      })
      this.$router.push({name: QuizRouter.RESULTS, params: this.$route.params});
    }
  }

  @Watch('quizzes')
  onQuizzesLoad(quizzes: IApiTalentQuiz[] | null) {
    if(quizzes?.length){
      const quiz = this.quizzes.find((q) => q.quizId === this.id);
      this.quiz = quiz;
      if(quiz){
        const state = QUIZ_SERVICE.quizState(quiz);
        if(state === QUIZ_STATES.PASSED && quiz.userQuizId){
          this.continueQuiz(quiz.userQuizId);
          this.$router.push({name: QuizRouter.RESULTS, params: this.$route.params});
        }
        else if(state === QUIZ_STATES.FAILED){
          this.$router.push({name: AssesmentsRouter.INDEX})
        }
        else if(state === QUIZ_STATES.AVAILABLE){
          this.$router.push({name: QuizRouter.QUIZ, params: this.$route.params});
        }
        else if(state === QUIZ_STATES.PROGRESS && quiz.userQuizId){
          this.continueQuiz(quiz.userQuizId);
        }
      }
      else {
        this.$router.push({name: AssesmentsRouter.INDEX})
      }
    }
  }

  goToNextQuestion(){
    if(this.userQuizId){
      this.continueQuiz(this.userQuizId);
    }
  }

  next() {
    if (this.id) {
      this.$track.sendEvent('QuizQuestion', {
        quizId: this.quiz?.quizId,
        name: this.quiz?.name,
        questionId: this.question.questionId,
        answer: this.form.answer > -1 && this.form.answer !== '' ?
          this.question.metadata.choices[this.form.answer] : 'Unknown',
        timeLeft: this.timeLeft
      })
      this.sending = true;
      this.nextQuestion(this.form.answer > -1 && this.form.answer !== '' ? this.form.answer : undefined)
        .finally(() => {
          this.sending = false;
        })
    }
  }

  timeOut() {
    // @ts-ignore
    this.$rs.alert.open({
      title: 'You ran out of time on this question',
      body: '<p class="u-pb8">Make sure to answer the question within the given time frame.</p>' +
        '<p>If you don’t know the answer, it’s better to choose “I don’t know the answer” option and proceed.</p>',
      confirm: {
        text: 'Next question',
        action: () => {
          this.goToNextQuestion();
        }
      },
    })
  }

  mounted() {
    this.id = this.$route.params.quizId;
  }
}
