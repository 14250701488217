

















import Component from 'vue-class-component';
import Vue from 'vue';
import { Getter} from 'vuex-class';
import RsIcon from '@/shared/components/icon/icon.vue';
import RsDashboardJobStatusHeader from '@/modules/dashboard/components/global/job-status/job-status-header.vue';
import {QuizGetters} from '@/modules/quiz/store/getters';
import QuizModule from '@/modules/quiz';
import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import {UUID} from '@/shared/constants/UUID';
import {QUIZ_SERVICE} from '@/shared/services/quiz.service';
import {DeveloperRoleInfo} from '@/shared/constants/ROLES';

@Component({
  name: 'rs-quiz-header',
  components: {RsDashboardJobStatusHeader, RsIcon}
})
export default class QuizHeader extends Vue {
  @Getter(QuizGetters.GET_QUIZZES, {namespace: QuizModule.namespace}) quizzes: IApiTalentQuiz[];

  id: UUID | null = null;

  roleData(quiz: IApiTalentQuiz):DeveloperRoleInfo | undefined {
    return quiz && QUIZ_SERVICE.roleData(quiz) ;
  }

  get quiz(){
    if(this.id && this.quizzes.length){
      return this.quizzes.find((q) => q.quizId === this.id);
    }
  }

  mounted(){
    this.id = this.$route.params.quizId;
  }
}
