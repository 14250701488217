













import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
  name: 'rs-radio',
})
export default class RSRadio extends Vue {
  @Prop({required: true}) val: any;
  @Prop({required: true}) name?: string;
  @Prop({required: true}) value: any;

  @Prop({required: false}) type: string;

  checkedProxy = false;

  get checked(){
    return this.value;
  }

  set checked(val){
    this.checkedProxy = val
  }
}
